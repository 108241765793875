import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const TutorialsPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://www.youtube.com/playlist?list=PLoUOQU--JyXX0kw7WYUTVE2GVzF0C_s71')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>SketchWow Ninja School</>}
      
    />
  </Layout>
);

export default TutorialsPage;
